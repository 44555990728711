<template>
    <v-container fluid>    
      <v-progress-linear v-if="loading"
          indeterminate
        ></v-progress-linear>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-container fluid class="mt-12 py-12">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-img src="@/assets/fundo_conduril.png" max-width="35%"/>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select @change="languageChoosed" v-model="$i18n.locale" :items="languages" item-text="language" :label="$t('globals.choose_language')">
                                <template v-slot:selection="{  }">
                                    <flag class="mr-4" :iso="$i18n.locale == 'en' ? 'gb': $i18n.locale" /> {{getExtension()}}
                                </template>
                                <template v-slot:item="{ item }">
                                    <flag class="mr-4" :iso="item.language == 'en' ? 'gb' : item.language" />{{item.extension}}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card class="elevation-8 ma-0 pa-0" v-if="!loading">
                    <v-card-text class="ma-0 pa-0">
                      <CheckComplaint :complaint="complaint" :audio-url="audioUrl" @reload="reload"></CheckComplaint>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
  </template>
  <script>
import CheckComplaint from '@/components/complaints/CheckComplaint'
import Complaint from '@/api/Complaints.js'
import Languages from '@/api/Languages.js'
  
export default {
  components: {
    CheckComplaint
  },
  data(){
    return{
      loading: true,
      languages: [],
      complaint: null,
      audioUrl: null
    }
  },
  mounted(){
   
    this.fillBaseData()
    
  },
  methods:{    
    getExtension(){
        let returner = 'Português';
        this.languages.forEach(element => {
            
            if(element.language == this.$i18n.locale)
                returner = element.extension
        });

        return returner
    },

    async reload() {
      
      this.loading = true;
      try {
        const response = await Complaint.find(this.$route.params.password);
        const data = response.data;
        this.complaint = data;
        if (data.audio_path) {
          this.audioUrl = Complaint.getAudioUrl(data.audio_path);
        }
        this.loading = false;
        
      } catch (error) {
        
        this.loading = false;
      }
    },

    languageChoosed(l){
        this.$i18n.locale = l
    },

    async fillBaseData() {
      
      try {
        const languagesResponse = await Languages.list();
        this.languages = languagesResponse.data;

        const complaintResponse = await Complaint.find(this.$route.params.password);
        const data = complaintResponse.data;
        this.complaint = data;
        if (data.audio_path) {
          this.audioUrl = Complaint.getAudioUrl(data.audio_path);
         
        }
        this.loading = false;
        
      } catch (error) {
        
        this.loading = false;
      }
    }

  },
};
</script>
  