import BaseApi from "./BaseApi";

export default class Complaints extends BaseApi{
    construct() {}

    static async getIntroduction() {
        return await Complaints.getAxiosInstance().get(Complaints.getApiUrl() + 'introduction');
    }

    static async create(params) {
        return await Complaints.getAxiosInstance().post(Complaints.getApiUrl(),params);
    }

    static async find(password) {
        return await Complaints.getAxiosInstance().get(Complaints.getApiUrl() + password);
    }

    static async downloadFile(file, id) {
        let url = Complaints.getApiUrl() + 'download/' + file + '/' + id
        
        return await Complaints.getAxiosInstanceDownload(url)
    }

    static getAudioUrl(audioPath) {
        return BaseApi.getApiUrl() + "api/client/complaints/audio/" + audioPath.replace('complaints/voices/', '');
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/complaints/";
    }
}