<template>
    <v-card class="elevation-0 ma-0 pa-0">
      <v-card-title class="primary white--text text-h3 font-weight-bold">
        {{ method == 'create' ? $t('globals.create_complaint') : $t('globals.see_complaint')}}
      </v-card-title>
      <v-card-text class="mt-4">
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">          
              <v-row>
                <v-col cols="12" md="8">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.title')" name="Título" rules="required">
                    <v-text-field
                      v-model="fields.title"
                      filled rounded
                      :label="$t('globals.title') + '*'"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.category')" name="Categoria" rules="required">
                    <v-select
                      v-model="fields.category_id"
                      filled rounded
                      :items="categories"
                      prepend-inner-icon="mdi-account-details"
                      :label="$t('globals.category') + '*'"
                      :item-text="item => item.i18n[$i18n.locale]"
                      :item-value="item => item.id"
                      clearable
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="12">
                <v-switch
                  v-model="useVoice"
                  :label=" 'Gravar Voz'"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="12" v-if="!useVoice">
                <validation-provider v-slot="{ errors }" :vid="$t('globals.complaint')" name="Descrição" rules="required">
                  <v-textarea
                    filled rounded
                    v-model="fields.description"
                    :label="$t('globals.complaint') + '*'"
                    clearable
                    :error-messages="errors"
                  ></v-textarea>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="12" v-if="useVoice">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card class="elevation-2 v-sheet theme--light">
                      <v-card-title class="primary white--text">Gravação</v-card-title>
                      <v-card-text class="mt-2">
                        <v-row justify="center" align="center">
                          <v-btn @click="startRecording" class="error v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--small mt-3">
                            <v-icon>mdi-record</v-icon>
                          </v-btn>
                          <v-btn @click="stopRecording" class="error v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--small mt-3" :disabled="!isRecording">
                            <v-icon>mdi-stop</v-icon>
                          </v-btn>
                        </v-row>
                        <v-row justify="center" align="center" class="mt-3">
                          <p class="recording-message mt-3">Em baixo poderá ouvir a gravação original.</p>
                          
                        </v-row>
                        <v-row justify="center" align="center" class="mt-3">
                          <audio :src="fields.voice" controls></audio>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card class="elevation-2 v-sheet theme--light">
                      <v-card-title class="primary white--text">Voz distorcida</v-card-title>
                      <v-card-text class="mt-2 text-center">
                        <v-btn @click="playModifiedVoice" class="success v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--small">
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                        <v-btn class="error v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--small" disabled>
                          <v-icon>mdi-stop</v-icon>
                        </v-btn>
                        <p class="recording-message mt-3">Carregue no play para ouvir</p>

                        <v-row justify="center" align="center" class="mt-3">
                            <audio :src="fields.modifiedVoice" controls></audio>
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-col>

                </v-row>
              </v-col>

                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.confidential')" name="Descrição" rules="required">
                    <v-card class="elevation-2 ma-0 pa-0 rounded-lg" outlined>
                      <v-card-title>
                        <v-switch
                          inset
                          v-model="fields.confidential"
                          prepend-inner-icon="mdi-account-details"
                          :label="$t('globals.confidential')"
                          clearable
                          @change="changedSwitchesConfidential"
                          :error-messages="errors"
                        ></v-switch>
                      </v-card-title>
                      <v-card-text>
                        Explicar o que é confidencial
                      </v-card-text>
                    </v-card>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.anonymous')" name="Descrição" rules="required">
                    <v-card class="elevation-2 ma-0 pa-0 rounded-lg" outlined>
                      <v-card-title>
                        <v-switch
                          inset
                          v-model="fields.anonymous"
                          prepend-inner-icon="mdi-account-details"
                          :label="$t('globals.anonymous')"
                          clearable
                          @change="changedSwitchesAnonymous"
                          :error-messages="errors"
                        ></v-switch>
                      </v-card-title>
                      <v-card-text>
                        Explicar o que é Anónimo
                      </v-card-text>
                    </v-card>
                  </validation-provider>
                </v-col>
                <v-col cols="12" v-if="fields.confidential">
                  <v-card class="elevation-2 ma-0 pa-0 rounded-lg" outlined>
                    <v-card-title class="primary white--text">
                      {{$t('globals.personal')}}
                    </v-card-title>
                    <v-card-text class="mt-4">                        
                      <v-row>
                        <v-col cols="12" md="12">
                          <validation-provider v-slot="{ errors }" :vid="$t('globals.name')" name="Nome" rules="required">
                            <v-text-field
                              filled rounded
                              v-model="fields.person.name"
                              :label="$t('globals.name') + '*'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                          <validation-provider v-slot="{ errors }" :vid="$t('globals.email')" name="email" rules="|">
                            <v-text-field
                              filled rounded
                              v-model="fields.person.email"
                              :label="$t('globals.email')+ '*'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                          <validation-provider v-slot="{ errors }" :vid="$t('globals.contact')" name="Contact" rules="|">
                            <v-text-field
                              filled rounded
                              v-model="fields.person.telephone"
                              :label="$t('globals.contact') + '*'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>                
                  </v-card>
            </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="fields.files"
                    filled rounded
                    counter
                    multiple
                    :label="$t('globals.attach')"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  {{$t('globals.deactivate')}}
                </v-col>
                <v-col cols="12" md="4" v-for="user in users" :key="user.id">
                  <v-card 
                    shaped
                    outlined
                    :class="user.selected ? 'elevation-24 gradient' : 'elevation-0'"
                    >
                    <v-checkbox
                      v-model="user.selected"      
                    ></v-checkbox>
                    <v-card-title class="mt-n8">
                      <v-avatar
                          style="margin: auto;"
                          class="primary white--text text-h6"
                          size="72"
                        >
                            {{user.initials}}
                      </v-avatar>
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{user.name}}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                  {{$t('globals.save')}}
                </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          
        </validation-observer>
        <v-dialog
          v-model="dialog"
          width="600"
          persistent
        >

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{$t('globals.success')}}
            </v-card-title>

            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <v-alert
                    outlined
                    dense
                    type="warning"
                    prominent
                    border="left"
                  >
                  Esta será a única vez que o nosso software disponibiliza a sua password
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  Deve guardar a seguinte password. Guarde a mesma em segurança. Caso a perca não terá mais acesso a esta denúncia
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col cols="10">
                  <v-chip label>{{password}}</v-chip>
                </v-col>
                <v-col cols="2">              
                  <v-btn @click="copyToClipboard" icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>            
                <v-col cols="12">              
                  <v-btn class="primary" dark outlined @click="downloadPassword">
                    <v-icon class="mr-2">mdi-download-box</v-icon> Download
                  </v-btn>
                </v-col>
              </v-row>
              
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-switch class="text-center" 
                        :label="$t('globals.secure_pwd')" 
                        v-model="safe_password" />
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="dialog = false; $router.push('/' + $i18n.locale + '/complaint/success')"
                :disabled="safe_password == false"
              >
                {{$t('globals.go_ahead')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
      </v-card-text>
    </v-card>
  </template>
  <style>

  </style>
  <script>
  import Category from '@/api/Category.js'
  import Department from '@/api/Department.js'
  import User from '@/api/User.js'
  import Complaints from '@/api/Complaints.js'
  import RecordRTC from 'recordrtc'
  import PitchShift from 'soundbank-pitch-shift';
  import lamejs from 'lamejs';

  import ErrorSnackbar from '@/components/ui/ErrorSnackbar'
  
  export default {
    components: { 
      ErrorSnackbar
    },
    props: {
     complaint: Object,
     method: String,
   },
   watch: { 
    department: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    data: () => ({
        safe_password: false,
        dialog: false,
        loading: true,
        success: false,
        password: null,
        error: {
          title: null,
          message: null,
          show: false
        },
        categories: [],
        departments: [],
        users: [],
        mediaRecorder: null,
        audioChunks: [],
        recorder: null,
        isRecording: false,
        audioContext: null,
        pitchShift: null,
        useVoice: false,
        fields:{
            files: null,
            title: null,
            description: null,
            confidential: true,
            anonymous: false,
            category_id: null,
            voice: null,
            modifiedVoice: null,
            person: {
              name: null,
              email: null,
              telephone: null
            }
        },
    }),
    mounted(){
        this.fillBaseData()
        window.scroll(0, 0)
    },
    methods:{

      startRecording() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          alert("O seu navegador não suporta gravação de voz.");
          return;
        }
        this.loading = true;
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            this.recorder = RecordRTC(stream, {
              type: 'audio',
              mimeType: 'audio/wav',
              recorderType: RecordRTC.StereoAudioRecorder,
              desiredSampRate: 16000
            });
            this.recorder.startRecording();
            this.isRecording = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.error("Erro ao iniciar gravação:", error);
            alert("Erro ao iniciar gravação.");
          });
  },

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        const blob = this.recorder.getBlob();
        this.fields.voice = URL.createObjectURL(blob);
        this.fields.modifiedVoice = blob;
        this.isRecording = false;
        console.log("Gravação parada, blob criado:", blob);
      });
    }
  },

    convertToMp3(blob) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      const wavBuffer = reader.result;
      const wav = lamejs.WavHeader.readHeader(new DataView(wavBuffer));
      const samples = new Int16Array(wavBuffer, wav.dataOffset, wav.dataLen / 2);
      const mp3Encoder = new lamejs.Mp3Encoder(wav.channels, wav.sampleRate, 128);
      const mp3Data = [];
      let mp3Buffer = mp3Encoder.encodeBuffer(samples);

      if (mp3Buffer.length > 0) {
        mp3Data.push(mp3Buffer);
      }

      mp3Buffer = mp3Encoder.flush();
      if (mp3Buffer.length > 0) {
        mp3Data.push(mp3Buffer);
      }

      const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });
      this.fields.modifiedVoice = mp3Blob;
    };
  },

    playModifiedVoice() {
      if (!this.fields.voice) {
        alert("Nenhuma gravação disponível.");
        return;
      }
      fetch(this.fields.voice)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
          this.pitchShift = PitchShift(this.audioContext);
          this.pitchShift.transpose = -7;
          this.pitchShift.wet.value = 0.85;
          this.pitchShift.dry.value = 0.15;

          const biquadFilter = this.audioContext.createBiquadFilter();
          biquadFilter.type = "lowpass";
          biquadFilter.frequency.setValueAtTime(1500, this.audioContext.currentTime);
          biquadFilter.Q.value = 1;

          this.pitchShift.connect(biquadFilter);
          biquadFilter.connect(this.audioContext.destination);

          this.audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
            const source = this.audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(this.pitchShift);

            const mediaStreamDestination = this.audioContext.createMediaStreamDestination();
            this.pitchShift.connect(mediaStreamDestination);

            const recorder = new MediaRecorder(mediaStreamDestination.stream);
            const modifiedChunks = [];

            recorder.ondataavailable = (event) => {
              modifiedChunks.push(event.data);
            };

            recorder.onstop = () => {
              const modifiedBlob = new Blob(modifiedChunks, { type: 'audio/wav' });
              this.fields.modifiedVoice = modifiedBlob;
            };

            recorder.start();
            source.start();

            source.onended = () => {
              recorder.stop();
            };
          });
        })
        .catch(error => console.error("Erro ao reproduzir a voz modificada:", error));
    },
      
      recordVoice(){
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorder.start();
          const audioChunks = [];
          mediaRecorder.addEventListener("dataavailable", event => {
            audioChunks.push(event.data);
          });
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks);
            const audioUrl = URL.createObjectURL(audioBlob);
            this.fields.voice = audioUrl;
          });
          setTimeout(() => {
            mediaRecorder.stop();
          }, 3000);
        });
      },

      changedSwitchesAnonymous(item){
        if(item)
          this.fields.confidential = false
        else
          this.fields.confidential = true
      },
      changedSwitchesConfidential(item){
        if(item)
          this.fields.anonymous = false
        else
          this.fields.anonymous = true
      },
      downloadPassword() {
        let fileType = 'txt'
        let fileName = "password"
        var blob = new Blob([this.password], { type: fileType });

        var a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
      },
      copyToClipboard() {
        // Copy the text inside the text field
        navigator.clipboard.writeText(this.password);
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/admin/settings/departments');
      },      
      async fillBaseData(){
        this.loading = true

        Category.list().then(({data}) => {
          this.categories = data;
        });

        Department.list().then(({data}) => {
          this.departments = data;
        });

        User.list().then(({data}) => {
          data.forEach(element => {
            this.users.push({
              id: element.id,
              name: element.name,
              initials: element.initials,
              email: element.email,
              selected: true
            })
          });
        });


        this.loading = false
      },


    submit() {

    let selectedUsers = [];
    this.users.forEach(element => {
      if (element.selected)
        selectedUsers.push(element);
    });

    if (selectedUsers.length <= 0) {
      this.error.message = "Precisa de pelo menos um utilizador ativo para tratar do seu processo";
      this.error.show = true;
      return;
    }

    this.$refs.form.validate().then((result) => {
      console.log("Form validation result:", result);
      this.loading = true;

      if (!result) {
        this.loading = false;
        return;
      }

      const formData = new FormData();
      formData.append('anonymous', this.fields.anonymous);
      formData.append('category_id', this.fields.category_id);
      formData.append('confidential', this.fields.confidential);

      selectedUsers.forEach(element => {
        formData.append('users[]', element.id);
      });

      formData.append('description', this.fields.description || null);

      if (this.fields.files != null) {
        for (var i = 0; i < this.fields.files.length; i++) {
          let file = this.fields.files[i];
          formData.append('files[]', file);
        }
      }

      formData.append('person', JSON.stringify(this.fields.person));
      formData.append('title', this.fields.title);

      if (this.fields.modifiedVoice) {
        
        formData.append('voice', this.fields.modifiedVoice, 'audio.mp3');
      } else {
        console.warn("Nenhum modifiedVoice disponível");
      }

      for (let [key, value] of formData.entries()) {
        
        console.log(key, value);
      }

      

      Complaints[this.method](formData, this.fields.id).then((response) => {
        this.success = true;
        this.loading = false;
        this.password = response.data.password;
        this.dialog = true;
      }).catch(err => {
        this.loading = false;
        if (err.response.status == 422) {
          this.$refs.form.setErrors(err.response.data.errors);
          return;
        }
        this.error.title = "Erro " + err.response.status;
        this.error.message = err.response.data.message;
      });
    });
  },
  }
  }
  </script>

<style>
.gradient {
  background: rgb(13,31,43);
  background: linear-gradient(277deg, rgba(13,31,43,1) 1%, rgba(111,122,129,1) 100%, rgba(255,255,255,1) 100%);
}
.recording-message {
  text-align: center;
}
</style>
  