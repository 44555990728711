<template>
    <v-container>
        <v-card class="elevation-0" v-if="complaint != null">
            <v-card-title>
                Detalhes do Caso
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        Data
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.created_at}}
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        Titulo
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.title}}
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        Descrição
                    </v-col>
                    <v-col cols="12" md="8">
                        <template v-if="audioUrl">
                            <v-row justify="center" align="center" class="mb-3">
             
                                <v-col cols="12" class="d-flex">
                                    <audio :src="audioUrl" controls></audio>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            {{complaint.description}}
                        </template>
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        Categoria
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.category.i18n[$i18n.locale]}}
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        Estado
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.state == null ? '-' : complaint.state.i18n[$i18n.locale]}}
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        Departamento
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.department == null ? '-' : complaint.department.i18n[$i18n.locale]}}
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        Prioridade
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.severity == null ? '-' : complaint.severity.level}}
                    </v-col>
                </v-row>

                <v-row v-for="item in complaint.files" :key="item">
                    <v-col cols="12" md="4">
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-btn @click="downloadFile(item)" icon class="success">
                            <v-icon color="white">mdi-download-box</v-icon>
                        </v-btn>
                        {{item}}
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
            <v-row v-for="comment in complaint.comments" :key="comment.id">
                <v-col cols="12" md="1">
                    <v-avatar
                        v-if="comment.user == null"
                        class="orange darken-2 white--text text-h6"
                        size="56"
                    >
                        Yours
                    </v-avatar>
                    <v-avatar
                        v-else
                        class="primary white--text text-h6"
                        size="56"
                    >
                        {{comment.user.initials}}
                    </v-avatar>
                </v-col>
                <v-col cols="12" md="11" class="mt-4">
                    {{comment.message}}
                </v-col>   
                <v-col cols="12" style="position:relative;" class="mt-n4">                    
                    <span class="text-caption" style="bottom: 0; right: 0; position: absolute;">
                        {{comment.user == null ? (complaint.anonymous ? 'Anonymous' : complaint.person.name) : comment.user.name}} at {{comment.created_at}}
                    </span>
                </v-col>

                <v-col cols="12" md="12" v-if="comment.files.length > 0">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="primary white--text">
                                            <template v-slot:actions>
                                                <v-icon class="icon" color="white">$expand</v-icon>
                                            </template>
                                            Documentos ({{comment.files.length}})
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-col cols="12" md="12" v-for="f in comment.files" :key="f">
                                                <v-btn @click="downloadCommentFile(f, comment.id)" icon class="success mr-2">
                                                    <v-icon color="white">mdi-download-box</v-icon>
                                                </v-btn>
                                                <span class="text-caption">{{f}}</span>
                                            </v-col>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>      
                <v-col cols="12">
                    
                    <v-divider></v-divider>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea 
                        filled
                        label="Insira o seu comentário"
                        v-model="comment.message"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-file-input
                        multiple
                        v-model="comment.files"
                        label="Anexar ficheiros"
                    ></v-file-input>
                </v-col>
                <v-col cols="2">
                    <v-btn @click="addComment">Comentar</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        </v-card>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Comentário inserido com sucesso
        </DialogSuccess>

    </v-container>

    
</template>
<script>
import Complaints from '@/api/Complaints.js'
import Comment from '@/api/Comment.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue'

export default{
    props:{
        complaint: Object,
        audioUrl: String
    },
    components:{
        DialogSuccess
    },
    mounted(){

    },
    data(){
        return{
            success: false,
            comment:{
                message: null,
                files: null,
                user_id: null,
                complaint_id: null
            }
        }
    },
    methods:{

        onSuccess(){
            this.success = false
            this.$emit('reload')
        },
        addComment(){
            this.comment.complaint_id = this.complaint.id

            const formData = new FormData();
            formData.append('message', this.comment.message)
            formData.append('complaint_id', this.comment.complaint_id)
            formData.append('from_whistleblower', true)
            if(this.comment.files != null){
                for (var i = 0; i < this.comment.files.length; i++ ){
                    let file = this.comment.files[i];
                    formData.append('files[]', file);
                }
            }

            Comment['create'](formData).then(() => {
                this.success = true
                this.loading = false
            }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
            });
        },        
        downloadCommentFile(file, id){
            Comment.downloadFile(file, id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        downloadFile(file){
            Complaints.downloadFile(file, this.complaint.id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
    }
}
</script>

<style>
.gradient {
  background: rgb(13,31,43);
  background: linear-gradient(277deg, rgba(13,31,43,1) 1%, rgba(111,122,129,1) 100%, rgba(255,255,255,1) 100%);
}
.recording-message {
  text-align: center;
}
</style>